<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <export-excel-button
                  :resource="resource"
                  :query="query"
                  :params="params"
                  :loading="loading"
                  :repository="SurveyAnswerRepository"
                  :fields="exportFields"
                  :search-fields="searchFields"
                  :extra-queries="extraQueries"
                  :file-name="$t('breadcrumb.marketSurvey')"
                />
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :visible-columns="visibleColumns"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
          viewable
          :updatable="false"
          translatable
          :archived="params.archived"
        >
          <template #cell(isOnRoute)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isOnRoute"
            >
              {{ $t(`field.onRoute`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`field.offRoute`) }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.status == 2"
            >
              {{ $t(`field.visited`) }}
            </b-badge>
            <b-badge pill variant="primary" badge-glow v-else>
              {{ $t(`field.visiting`) }}
            </b-badge>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import ExportExcelFields from "./exportExcelFields";
import SearchInputs from "./searchInput";
import NColumnVisibility from "@/components/NColumnVisibility";
import ExportExcelButton from "@/components/ExportExcelButton";

const SurveyAnswerRepository = Repository.get("surveyAnswer");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BFormGroup,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    NColumnVisibility,
    ExportExcelButton,
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.marketSurvey;
      },
      set(value) {
        this.$store.commit("column/SET_MARKET_SURVEY_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        regionId: this.$route.query.regionId || [],
        agentId: this.$route.query.agentId || [],
        outletId: this.$route.query.outletId || [],
        salespersonId: this.$route.query.salespersonId || [],
        status: this.$route.query.status || [],
        isOnRoute: this.$route.query.isOnRoute || null,
        visitedDate: this.$route.query.visitedDate || [],
        surveyId: this.$route.query.surveyId || [],
        surveySectionId: this.$route.query.surveySectionId || [],
        surveyQuestionId: this.$route.query.surveyQuestionId || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        regionId: this.$route.query.regionId || [],
        agentId: this.$route.query.agentId || [],
        outletId: this.$route.query.outletId || [],
        salespersonId: this.$route.query.salespersonId || [],
        status: this.$route.query.status || [],
        isOnRoute: this.$route.query.isOnRoute || null,
        visitedDate: this.$route.query.visitedDate || [],
        surveyId: this.$route.query.surveyId || [],
        surveySectionId: this.$route.query.surveySectionId || [],
        surveyQuestionId: this.$route.query.surveyQuestionId || [],
      },
      extraQueries: [
        {
          searchby: "type",
          searchoperator: "=",
          search: 1,
        },
      ],
      SurveyAnswerRepository,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.search();
    },
    getData() {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();

      SurveyAnswerRepository.index({
        ...this.query,
        searchFields: this.searchFields,
        query: this.extraQueries,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const exportFields = ExportExcelFields;
    const searchFields = SearchInputs;
    const resource = "market-survey";
    const route = "market-survey";

    return {
      fields,
      exportFields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
