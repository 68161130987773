export default [
  {
    key: 'regionId',
    label: 'field.region',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
  },
  {
    key: 'agentId',
    label: 'field.agent',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'agent',
    selectionKey: 'id',
    selectionLabel: 'agentCode',
  },
  {
    key: 'outletId',
    label: 'field.outlet',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'outlet',
    selectionKey: 'id',
    selectionLabel: 'label',
    localization: true,
  },
  {
    key: 'salespersonId',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
  },
  {
    key: 'status',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'field.visiting', value: '1' },
      { text: 'field.visited', value: '2' },
    ],
  },
  {
    key: 'isOnRoute',
    label: 'field.type',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.onRoute', value: '1' },
      { text: 'field.offRoute', value: '0' },
    ],
  },
  {
    key: 'visitedDate',
    label: 'field.visitedDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
  {
    key: 'surveyId',
    label: 'field.survey',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'survey',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'surveySectionId',
    label: 'field.section',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'surveySection',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'surveyQuestionId',
    label: 'field.question',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'surveyQuestion',
    selectionKey: 'id',
    selectionLabel: 'question',
    localization: true,
    lg: 6,
  },
]
